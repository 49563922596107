<template>
  <v-snackbar
    v-for="(item, i) in items"
    :key="item.id"
    :timeout="item.timeout"
    :model-value="true"
    :style="{ 'margin-bottom': `${((items.length - (i + 1)) * 60 + spaceForIntercom) + 12}px`, right: 0 }"
    rounded="xl"
    :color="item.type || 'primary'"
    :location="smAndDown ? 'bottom' : 'right'"
    @update:model-value="dismiss(item.id)"
  >
    {{ item.message }}
  </v-snackbar>
</template>

<script setup>
import { useDisplay } from 'vuetify'
import { useSnackbarsStore } from '@/store/snackbars'
const { items } = storeToRefs(useSnackbarsStore())
const { dismiss } = useSnackbarsStore()
const { smAndDown } = useDisplay()
const spaceForIntercom = 68
</script>
