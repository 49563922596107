import { ref } from 'vue'
export const promoter = ref(null)
export default function () {
  if (!document.getElementById('firstpromoter-script')) {
    (function (w) {
      w.fpr = w.fpr || function () {
        w.fpr.q = w.fpr.q || []
        w.fpr.q[arguments[0] === 'set' ? 'unshift' : 'push'](arguments)
      }
    })(window)

    window.fpr('init', { cid: 'iwm0jnt4' })
    window.fpr('click')
    window.fpr('onReady', async ({ ref_id }) => {
      if (!ref_id) { return }
      promoter.value = await fetch(import.meta.env.VITE_API_BASE_URL + '/fpr/validate?ref_id=' + ref_id)
        .then(response => response.json())
    })

    const script = document.createElement('script')
    script.id = 'firstpromoter-script'
    script.async = true
    script.src = 'https://cdn.firstpromoter.com/fpr.js'
    document.head.appendChild(script)
  }
}
