/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
// The commented values below should be defaults
export default createVuetify({
  defaults: {
    global: {
      ripple: false,
    },
    VBtn: {
      class: 'text-none',
    },
  },
  display: {
    mobileBreakpoint: 'md',
    // Note: changing these thresholds do not propagate to css media so leave as is
    // thresholds: {
    //   xs: 0,
    //   sm: 600,
    //   md: 960,
    //   lg: 1280,
    //   xl: 1920,
    //   xxl: 2560
    // },
  },
  theme: {
    themes: {
      light: {
        colors: {
          // background: '#FFFFFF',
          'surface': '#FFFFFF',
          // 'surface-bright': '#FFFFFF',
          // 'surface-light': '#EEEEEE',
          // 'surface-variant': '#424242',
          // 'on-surface-variant': '#EEEEEE',
          'primary': '#C11CDE',
          'secondary': '#AF75BC',
          'secondary-lighten': '#E2DCF5',
          'background': '#F2F5F7',
          'gray': '#F2F5F7',
          'error': '#aa0000',
          'success': '#11B02A',
          'warning': '#EDB600',
          'black': '#0A000B', // added black here to be able to use text-black
        },
      },
    },
  },
})
