import posthog from 'posthog-js'
import { mode } from '@/config'

export const initializePosthog = (whichApp) => {
  const PROJECT_KEYS = Object.freeze({
    admin: {
      production: 'phc_kT6US6ru5i6dqSLKqLeMRUJizvHxIE15haFIla4cE5U',
    },
    app: {
      production: 'phc_HGDDQpEnTLm3bYitwnC2SJimcGmHnz4r7rU2IJBpEAv',
      staging: 'phc_5NjMNUVpHxQh6mGYhmc2EFuW6CiAqZnrY3dEymdouHx',
      development: 'phc_tSA3UyM1UIUT85mGOUTO0JnQxRXWfCXHPhSzP7s9YdI',
    },
  })

  const API_HOSTS = Object.freeze({
    production: window.location.origin + '/ingest',
    staging: window.location.origin + '/ingest',
    development: 'https://us.i.posthog.com',
  })

  const projectKey = PROJECT_KEYS[whichApp][mode]
  // if posthog is not initialized, the methods called throughout the app do nothing
  if (!projectKey) { return }
  posthog.init(projectKey, {
    api_host: API_HOSTS[mode],
    ui_host: 'https://us.posthog.com',
    persistence: 'localStorage+cookie',
    person_profiles: 'identified_only',
    opt_out_capturing_by_default: true,
    opt_out_persistence_by_default: true,
    capture_pageview: false,
    capture_pageleave: false,
  })
}

export default posthog
