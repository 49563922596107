import { defineStore } from 'pinia'
import { v1 as uuidv1 } from 'uuid'

// Define types for snackbar items
export interface SnackbarItem {
  id: string
  message: string
  type: 'success' | 'info' | 'warning' | 'error'
  timeout: number
}

// Define input type that makes id and timeout optional
export interface SnackbarInput {
  id?: string
  message: string
  type: 'success' | 'info' | 'warning' | 'error'
  timeout?: number
}

export const useSnackbarsStore = defineStore('snackbars', () => {
  const items = ref<SnackbarItem[]>([])

  const newSnackbar = (snackbar: SnackbarInput): void => {
    const newSnackbar: SnackbarItem = {
      id: snackbar.id || uuidv1(),
      message: snackbar.message,
      type: snackbar.type,
      timeout: snackbar.timeout || 3000,
    }
    items.value.push(newSnackbar)
  }

  const dismiss = (id: string): void => {
    items.value = items.value.filter(snackbar => snackbar.id !== id)
  }

  const success = (message: string): void => {
    newSnackbar({ message, type: 'success' })
  }

  const info = (message: string): void => {
    newSnackbar({ message, type: 'info' })
  }

  const warn = (message: string): void => {
    newSnackbar({ message, type: 'warning' })
  }

  const error = (error: unknown): void => {
    let message: string

    if (error === null || error === undefined) {
      return
    }

    if (typeof error === 'string') {
      message = error
    }
    else if (error instanceof Error) {
      message = error.message
    }
    else if (typeof error === 'object') {
      // Try to get message from error object
      const errorObj = error as { message?: string, error?: string }
      message = errorObj.message || errorObj.error || 'An error occurred'
    }
    else {
      console.error(error)
      message = 'An error occurred'
    }

    // If there is already a snackbar with the same message, don't show it again
    if (items.value.find(x => x.message === message)) {
      return
    }

    newSnackbar({ message, type: 'error', timeout: 10000 })
  }

  return {
    items,
    new: newSnackbar,
    dismiss,
    success,
    info,
    warn,
    error,
  }
})
