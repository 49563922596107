// Utilities
import { defineStore } from 'pinia'
import posthog from 'posthog-js'
import * as Sentry from '@sentry/vue'
import Parse from '@/services/parse'

export const useAdminStore = defineStore('admin', {
  state: () => ({
    initialized: null,
    user: Parse.User.current(),
    users: [],
    counts: {},
    statuses: {},
  }),
  getters: {
    isSuperAdmin: state => state.user.get('accType') === 'superadmin',
    isAdmin: state => state.user.get('accType')?.includes('admin'),
  },
  actions: {
    async initialize() {
      posthog.opt_in_capturing()
      posthog.identify(this.user.id, {
        username: this.user.get('username'),
        email: this.user.get('email'),
      })
      Sentry.setUser({
        id: this.user.id,
        username: this.user.get('username'),
        email: this.user.get('email'),
      })
      const [users, counts] = await Parse.Cloud.run('initialize-admin')
      this.users = users
      this.counts = counts
      this.initialized = new Date()
      try {
        this.statuses = await Parse.Cloud.run('admin-status')
      }
      catch (error) {
        console.error(error)
      }
    },
    async logout() {
      document.getElementsByTagName('html')[0].classList.add('loading')
      await Parse.User.logOut()
      posthog.reset()
      Sentry.setUser(null)
      window.location.href = '/'
    },
  },
})
