import { defineRule, configure } from 'vee-validate'
import { required, email, min, max } from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'
import { getDomain } from 'tldts'
import en from '@vee-validate/i18n/dist/locale/en.json'

defineRule('required', required)
defineRule('email', email)
defineRule('min', min)
defineRule('max', max)

// alphanumeric with hypens
defineRule('referralCode', (value) => {
  return /^[a-z0-9_-]+$/i.test(value)
    ? true
    : 'The referral code can only contain letters, numbers, hyphens, and underscores.'
})
// custom url validator that accepts urls with or without http(s)://
defineRule('url', (value) => {
  value = value?.trim()
  if (!value) { return true }
  const domain = getDomain(value)
  if (!domain) { return false }
  try {
    if (!value.startsWith('http')) {
      value = 'https://' + value
    }
    new URL(value)
  }
  catch (error) {
    console.error(error)
    return false
  }
  return true
})

defineRule('urls', (value) => {
  value = value?.trim()
  if (!value) { return true }
  const links = value.split(/\r?\n/)
    .map(line => line.trim().replace(/,$/, ''))
    .filter(Boolean)
    .map(line => line.startsWith('http') ? line : `https://${line}`)
  for (const link of links) {
    const domain = getDomain(link)
    if (!domain) { return false }
    try {
      new URL(link)
    }
    catch (error) {
      console.error(error)
      return false
    }
  }
  return true
})

// custom unique validator
defineRule('unique_username', (value, array) => {
  value = (value || '').trim().toLowerCase()
  if (!value) { return true }
  return !array.some(item => item?.toLowerCase() === value)
})

// custom unique validator
defineRule('unique_link', (value, array) => {
  value = value?.trim()?.toLowerCase()
  if (!value) { return true }
  const link = new URL(value.startsWith('http') ? value : 'https://' + value)
  if (link.pathname.endsWith('/')) {
    link.pathname = link.pathname.slice(0, -1)
  }
  value = link.origin + link.pathname
  return !array.some(item => item?.toLowerCase() === value)
})

en.messages.unique_username = 'You already added this name!'
en.messages.unique_link = 'You already added this link!'
configure({
  generateMessage: localize({
    en,
  }),
})
