<template>
  <v-app :class="{ mobile: $vuetify.display.smAndDown }">
    <router-view ref="appRouterView" />
    <snackbars />
    <reload-prompt />
  </v-app>
  <!-- vercel speed-insights -->
  <speed-insights />
</template>

<script setup>
import { SpeedInsights } from '@vercel/speed-insights/vue'

// The index.html starts with the loading state and hides the page content, until the app is mounted
const appRouterView = ref(null)
onMounted(async () => {
  // Wait for the first component in router view (layout) to be mounted
  while (!appRouterView.value?.$?.subTree?.component?.proxy) {
    await new Promise(resolve => setTimeout(resolve, 100))
  }
  // Remove the loading state
  const componentInstance = appRouterView.value?.$?.subTree?.component?.proxy
  if (componentInstance) {
    document.getElementsByTagName('html')[0]?.classList?.remove('loading')
  }
})
</script>
