import * as Sentry from '@sentry/vue'
import posthog from '@/services/posthog'
import Parse from 'parse/dist/parse.min.js'
import { mode } from '@/config'
export const registerErrorHandler = (app) => {
  Sentry.init({
    app,
    dsn: 'https://d540764b2dcd9d4e48b47daf004b4974@o4507210930585600.ingest.de.sentry.io/4507210931830864',
    environment: mode,
    enabled: mode !== 'development',
    // debug: mode === 'development',
    trackComponents: true,
    // ignoreErrors: ["fb_xd_fragment", /^Exact Match Message$/],
    // ignoreTransactions: ["partial/match", /^Exact Transaction Name$/],
    integrations: [
      posthog.sentryIntegration({
        organization: 'bruqi',
        projectId: '4507210931830864',
        severityAllowList: ['error', 'info'], // optional: here is set to handle captureMessage (info) and captureException (error)
      }),
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 0.05,
    tracePropagationTargets: [import.meta.env.VITE_API_BASE_URL],
  })

  const handleError = (error) => {
    if (!error) { return true }
    if (error?.code === Parse.Error.INVALID_SESSION_TOKEN) {
      window.localStorage.clear()
      window.location.reload()
      return true
    }
    const message = error?.message || error?.error || error
    if (message.startsWith('ResizeObserver')) { return true }
    mode === 'development' && console.error(error)
    return Sentry.captureException(error)
  }
  // error event: This event is triggered when an error occurs in the global scope, such as syntax errors in scripts, failed network requests, or runtime errors that are not caught by try-catch blocks.
  window.addEventListener('error', event => handleError(event.error))
  // unhandledrejection event: This event is triggered when a promise is rejected, but there is no corresponding .catch() handler to handle the rejection. This typically occurs when a promise is rejected but no .catch() or .then() with a second argument (for handling rejections) is attached to the promise chain.
  window.addEventListener('unhandledrejection', ({ reason: error }) => handleError(error))
  return handleError
}

export default registerErrorHandler
